import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { nanoid } from 'nanoid';
import { Box } from '@mui/material';
import * as Sentry from '@sentry/react';
import { Allotment } from 'allotment';
import 'allotment/dist/style.css';
import { LoadingContext } from 'contexts/LoadingContext';
import useWindowSize from 'contexts/useWindowSize';
import { useBeforeUnload } from 'react-use';

import useExtraction from '@/contexts/useExtraction';
import ProcessPreview from '@/components/UploadModal/ProcessPreview';
import CommissionMapper from '@/components/UploadModal/processFlow/CommissionMapper';
import useCommonData from '@/components/UploadModal/processFlow/hoc/useCommonData';
import useFileToData from '@/components/UploadModal/processFlow/hoc/useFileToData';
import {
  DocumentTypeE,
  ErrorMsg,
  ProcessMethodE,
  ProcessorFormatModel,
  SpreadSheetProps,
} from '@/components/UploadModal/processFlow/process.d';
import useBeforeUnloadPage from '@/contexts/useBeforeunloadPage';
import API from '@/services/API';
import useAutoPolling from '@/contexts/useAutoPolling';
import StickyTips from './StickyTips';
import { tool } from '@/common/tools';
import { DocumentProcessTypes } from '@/types';
import {
  IMappingModel,
  IDocumentModel,
  ProcessFormModel,
  IProcessorModel,
} from './model';
import { ProcessSuccessStatus } from './config';

interface ProcessUploadsProps {
  rowData: Partial<IDocumentModel>;
  setRowData: React.Dispatch<React.SetStateAction<Partial<IDocumentModel>>>;
  mpData: {
    mappings: IMappingModel[];
    processors: IProcessorModel[];
  };
  actionCount: number;
  setActionCount: React.Dispatch<React.SetStateAction<number>>;
  timerCountRef: any;
  feedback: string;
}

const ProcessUploads = (
  {
    rowData,
    mpData,
    setRowData,
    actionCount,
    setActionCount,
    timerCountRef,
    feedback,
  }: ProcessUploadsProps,
  ref
) => {
  const [processForm, setProcessForm] = useState<ProcessFormModel>({
    mapping: 0,
    newMappingCarrier: null,
    newMappingName: '',
    processor: '',
    prompt: '',
    promptText: '',
    method: '',
    fileName: '',
    fileType: DocumentTypeE.Statement,
    selectedSheet: '',
  });

  const tipRef = useRef<{ ignoreErrors: boolean }>(null);

  const [fileType, setFileType] = useState<DocumentTypeE>(
    DocumentTypeE.Statement
  );

  const [spreadsheet, setSpreadsheet] = useState<SpreadSheetProps | null>();
  const [selectedSheet, setSelectedSheet] = useState('');
  const [startPolling, setStartPolling] = useState(false);
  const [pollingParams, setPollingParams] = useState({
    jobId: '',
    uploadId: rowData.id,
  });

  const [errors, setErrors] = useState<ErrorMsg>({});
  const [processActionMap, setProcessActionMap] = useState({});

  const [processFormatData, setProcessFormatData] =
    useState<ProcessorFormatModel>({
      mappingOptions: {},
      cmsTotal: '',
      data: [],
      rowData: [],
    });
  const { loadingConfig, setLoadingConfig } = useContext(LoadingContext);
  const { data: prompts } = API.getBasicQuery('prompts');

  useBeforeUnload(
    loadingConfig.loading,
    'You have unsaved changes, are you sure you want to leave?'
  );
  useBeforeUnloadPage(
    loadingConfig.loading,
    'You have unsaved changes, are you sure you want to leave?'
  );

  const addActionCount = useCallback(
    (type?: DocumentProcessTypes) => {
      setActionCount(actionCount + 1);
      if (!type) return;

      setProcessActionMap((prev) => {
        return {
          ...prev,
          [type]: (prev[type] ?? 0) + 1,
        };
      });
    },
    [actionCount]
  );

  useEffect(() => {
    setLoadingConfig({
      loading: startPolling,
      message: 'Processing document...',
      allowClose: true,
    });
  }, [startPolling]);

  const pollingFetchExtractTable = async () => {
    const extractionOption = rowData.method?.split('::') || [];
    const extractionId =
      extractionOption.length > 1 ? extractionOption.pop() : '';
    const extractionStrId = `jobId=${pollingParams.jobId}&uploadId=${pollingParams.uploadId}&extractionId=${extractionId}`;
    try {
      const url = `${process.env.REACT_APP_API}/api/documents/extractData?${extractionStrId}`;
      const _res = await fetch(url, {
        method: 'GET',
        headers: await API.getHeaders(),
      });
      const res = await _res.json();
      if (res.error || res.type === 'expired') {
        setErrors({
          ...errors,
          pollingRow: res.message || res.error,
        });
        setStartPolling(false);
        Sentry.captureException(res.error || res.message);
        return null;
      }
      return res;
    } catch (e) {
      setErrors({
        ...errors,
        pollingRow: `Error uploading file (${(e as unknown as any).message})`,
      });
      setStartPolling(false);
      Sentry.captureException(e);
      return null;
    }
  };

  const checkDataCondition = (data) => {
    const successList = ProcessSuccessStatus;
    if (successList.includes(data?.JobStatus)) {
      setErrors({
        ...errors,
        pollingRow: '',
      });
      return true;
    }
    return false;
  };

  const { data: extractTableData } = useAutoPolling(
    pollingFetchExtractTable,
    checkDataCondition,
    8000,
    startPolling
  );
  const { width: winWidth } = useWindowSize();
  const [previewWidth, setPreviewWidth] = useState(winWidth * 0.25);

  const { fileData, file, setFile } = useFileToData(rowData, processForm);
  const { fields } = useCommonData(fileType, file);
  // extract table hook
  const {
    setExtraction,
    setExtractionMethod,
    extractTableJson,
    extractTableRaw,
  } = useExtraction();

  const mappingsPoster = API.getMutation('mappings', 'POST');
  const documentsPatcher = API.getMutation('documents', 'PATCH');
  const statementDataPoster = API.getMutation('statement_data', 'POST');
  const reportDataPoster = API.getMutation('report_data', 'POST');

  const { data: extractionData = [], isLoading: extractionLoading } =
    API.getBasicQuery('extractions', `document_id=${rowData.id}`, !!rowData.id);

  const setDataToSpreetSheet = (data: any[][][]) => {
    if (data && data.length > 0) {
      const newSpreadsheet = {
        getSheets: () => data.map((e, i) => `${i}`),
        getJson: (i) => data?.[i] ?? [],
      };
      setSpreadsheet(newSpreadsheet);
    }
  };

  /** Polling result */
  useEffect(() => {
    const doAction = async () => {
      if (extractTableData && !extractionLoading) {
        setStartPolling(false);
        setExtractionMethod('extractTable');
        setExtraction(JSON.stringify(extractTableData));
        setRowData(() => {
          const method = `extractTable::${extractionData[0]?.str_id ?? ''}::${
            extractionData[0]?.id ?? ''
          }`;
          return {
            ...rowData,
            extractions: extractionData,
            method,
          };
        });
        setErrors({
          ...errors,
          dataRows: '',
          expiredRow: '',
          pollingRow: '',
        });
      }
    };
    doAction();
  }, [extractTableData, extractionData, extractionLoading]);

  useEffect(() => {
    if (processForm.prompt && prompts) {
      const target = prompts.find((item) => item.str_id === processForm.prompt);
      if (target && target.prompt) {
        setProcessForm((prev) => {
          return {
            ...prev,
            promptText: target.prompt,
          };
        });
      }
    }
  }, [processForm.prompt, prompts]);

  useEffect(() => {
    if (!fileData) {
      return;
    }
    if (fileData.error) {
      setErrors({
        ...errors,
        dataRows: fileData.error,
      });
    } else {
      const { data, type } = fileData;
      if (type === 'spreadsheet') {
        setSpreadsheet(data as unknown as SpreadSheetProps);
      } else if (type === 'documentAI') {
        setDataToSpreetSheet(data.table);
        setExtractionMethod('documentAI');
        setExtraction(data);
      } else if (type === 'extractTable') {
        // check the document is processing or not
        const parseData = JSON.parse(data);
        if (parseData?.JobStatus === 'Processing') {
          setErrors({
            ...errors,
            pollingRow:
              'This document is taking a while to process. You can continue to wait or leave this page and check back later. Document extractions usually finish within a couple of minutes.',
          });

          setPollingParams({
            jobId: parseData.JobId,
            uploadId: rowData.id,
          });
          setStartPolling(true);
          return;
        }

        if (parseData?.ProTip || parseData?.message) {
          setErrors({
            ...errors,
            expiredRow: `${parseData.ProTip} (${parseData.message})`,
          });
          return;
        }

        setExtractionMethod('extractTable');
        setExtraction(data);
        // check processor
        const getExtraction = async () => {
          setRowData(() => {
            const method = `extractTable::${extractionData[0]?.str_id ?? ''}::${
              extractionData[0]?.id ?? ''
            }`;
            return {
              ...rowData,
              extractions: extractionData,
              method,
            };
          });
        };
        if (
          rowData.method &&
          !rowData.method.includes('::') &&
          !extractionLoading
        ) {
          getExtraction();
        }
        setErrors({
          ...errors,
          dataRows: '',
          expiredRow: '',
        });
      } else if (type === 'adobeExtract') {
        setDataToSpreetSheet(data);
        setExtractionMethod('adobeExtract');
        setExtraction(data);
      } else if (type === 'gemini') {
        setProcessForm((pre) => {
          return {
            ...pre,
            method: type,
          };
        });
      }
    }
  }, [fileData, extractionLoading]);

  /**
   * set extract table json to spreadsheet
   */
  useEffect(() => {
    if (extractTableJson) {
      const formatData = [JSON.parse(extractTableJson)];
      setDataToSpreetSheet(formatData);
    }
  }, [extractTableJson]);

  useEffect(() => {
    if (rowData.type) {
      setFileType(rowData.type as DocumentTypeE);
    }
  }, [rowData]);

  useEffect(() => {
    // Filter the mapping value which it's name includes company name from the rowData
    const targetMapping = mpData.mappings?.find((mapping) => {
      return mapping.carrier?.str_id === rowData.company_str_id;
    });
    let _method = rowData.method as string;
    if (_method) {
      const isGemini = _method?.includes('gemini');
      const isExtractTable = _method?.includes('extractTable');
      const isMapping = _method?.includes('mapping');
      const isAdobePDFExtract = _method?.includes('adobeExtract');

      if (isMapping) _method = ProcessMethodE.Mapping;
      if (isExtractTable || isAdobePDFExtract)
        _method = ProcessMethodE.Processor;
      if (isGemini) _method = ProcessMethodE.Gemini;
    }

    setProcessForm((prev) => ({
      ...prev,
      fileName: file?.name || '',
      selectedSheet,
      mapping: targetMapping ? targetMapping.str_id : 0,
      newMappingName:
        prev.newMappingName ||
        (rowData.companies ? `${rowData.companies.company_name} mapping` : ''),
      method: _method,
      prompt: rowData.prompt,
      newMappingCarrier: rowData.companies ? rowData.companies : 0,

      fileType:
        fileType === DocumentTypeE.Statement
          ? 'Commission Statement'
          : 'Policy Report',
    }));
  }, [file, fileType, selectedSheet, mpData.mappings]);

  //* ****************************Upload mapping start************************************/
  const uploadMapping = async () => {
    try {
      // Upload
      if (file) {
        let mapping;
        if (processForm.mapping === 0) {
          const newMapping = {
            name: processForm.newMappingName,
            type: fileType,
            carrier_id:
              processForm.newMappingCarrier &&
              typeof processForm.newMappingCarrier !== 'number'
                ? processForm.newMappingCarrier?.str_id
                : processForm.newMappingCarrier,
            mapping: processFormatData.mappingOptions,
          } as any;
          const res = await mappingsPoster.mutateAsync(newMapping);
          if (res.error) {
            setErrors({
              ...errors,
              upload: `Error uploading mapping (${res.error})`,
            });
            Sentry.captureException(res.error);
            return null;
          }
          mapping = res.str_id;
        } else {
          mapping = processForm.mapping;
        }
        return mapping;
      }

      return null;
    } catch (e) {
      setErrors({
        ...errors,
        upload: `Error uploading mapping (${(e as unknown as any).message})`,
      });
      Sentry.captureException(e);
      return null;
    }
  };

  const bulkUpload = async ({ mapping }) => {
    try {
      const importId = nanoid();
      const mappingList = Object.keys(processFormatData.mappingOptions);
      const formatFields: string[] = [];
      mappingList.forEach((k) => {
        const targetField = fields[k];
        if (
          targetField?.normalizer &&
          typeof targetField.normalizer === 'function'
        ) {
          formatFields.push(k);
        }
      });

      const copyData = [...processFormatData.rowData];
      const normalizedData = copyData.map((datum) => {
        // format value
        Object.keys(datum).forEach((k) => {
          if (formatFields?.includes(k)) {
            datum[k] = fields[k].normalizer!(datum[k]);
          }
        });

        if (datum.contacts && typeof datum.contacts === 'string') {
          datum.contacts = datum.contacts.split(',');
        }

        if (datum.commission_rate) {
          datum.new_commission_rate =
            tool.convertToNumber(datum.commission_rate) / 100;
        }

        if (datum.carrier_rate) {
          datum.new_carrier_rate =
            tool.convertToNumber(datum.carrier_rate) / 100;
        }
        return {
          ...datum,
          type: fileType,
          document_id: rowData.str_id,
          import_id: importId,
        };
      });

      const resList: any[] = [];
      if (fileType === 'statement') {
        for (let i = 0; i < normalizedData.length; i += 1000) {
          const chunk = normalizedData.slice(i, i + 1000);

          const res = await statementDataPoster.mutateAsync(chunk);
          resList.push(res);
          if (res.error) {
            setErrors({
              ...errors,
              upload: `Error uploading data (${res.error})`,
            });
            Sentry.captureException(res.error);
            return null;
          }
        }
        // await statementDataPoster.mutateAsync(dataArray);
      } else if (fileType === 'report') {
        for (let i = 0; i < normalizedData.length; i += 1000) {
          const chunk = normalizedData.slice(i, i + 1000);

          const res = await reportDataPoster.mutateAsync(chunk);
          resList.push(res);
          if (res.error) {
            setErrors({
              ...errors,
              upload: `Error uploading data (${res.error})`,
            });
            Sentry.captureException(res.error);
            return null;
          }
        }
      } else {
        throw new Error(`Unsupported file type: ${fileType}`);
      }
      const filename = rowData.override_filename || rowData.filename;
      const notes =
        fileType === 'statement' ? 'New statement data' : 'New report data';
      const allSuccess = resList.every((res) => res.status === 'OK');

      const processDuration = (timerCountRef.current?.totalSeconds || 0) * 1000;

      const processingLogParams = {
        type: 'document_processing',
        duration: processDuration,
        status: allSuccess ? 'completed' : 'failed',
        params: filename,
        notes,
        stats: {
          count: normalizedData.length,
        },
      };

      const metadata = {
        status: allSuccess ? 'Success' : 'Failed',
        count: normalizedData.length,
      };
      const importsData = {
        process_duration: processDuration,
        summed_total_amount: processFormatData.cmsTotal,
        count: normalizedData.length,
        type: fileType,
        document_str_id: rowData.str_id,
        company_str_id: rowData?.companies?.str_id,
        str_id: importId,
        metadata,
        status: allSuccess ? 'Success' : 'Failed',
        feedback: feedback,
        process_count: actionCount,
        process_action_records: Object.keys(processActionMap).length
          ? processActionMap
          : null,
      };

      const params = {
        id: rowData.id,
        company_str_id: rowData?.companies?.str_id,
        mapping,
        processor: processForm.processor,
        prompt: processForm.prompt,
        type: fileType,
        method: processForm.method,
        state: 'active',

        status: 'processed',
        import_id: importId,
        imported_at: new Date().toISOString(),

        processing_log: processingLogParams,
        imports_log: importsData,
      };
      const resp = await documentsPatcher.mutateAsync(params);
      if (resp.error) {
        setErrors({
          ...errors,
          upload: `Error uploading data (${resp.error})`,
        });
        Sentry.captureException(resp.error);
        return null;
      }
      return normalizedData;
    } catch (e) {
      setErrors({
        ...errors,
        upload: `Error uploading data (${(e as unknown as any).message})`,
      });
      Sentry.captureException(e);
    }
  };

  const submit = async () => {
    if (errors.missingField && !tipRef.current?.ignoreErrors) {
      return {
        error: errors.missingField,
      };
    }
    const mappingStrId = await uploadMapping();
    if (!mappingStrId) {
      return;
    }
    const res = await bulkUpload({ mapping: mappingStrId });
    setExtraction('');
    setFile(null);
    return !!res;
  };
  //* ****************************Upload mapping end************************************/

  const onDragFinished = (size) => {
    setPreviewWidth(size[0]);
  };

  useImperativeHandle(ref, () => ({
    submit,
  }));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'relative',
        height: '100%',
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Allotment
          onDragEnd={onDragFinished}
          className="flex flex-1 h-full"
          defaultSizes={[25, 75]}
        >
          <Allotment.Pane>
            {previewWidth ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                }}
              >
                <Box sx={{ flex: 1, overflow: 'auto' }}>
                  <ProcessPreview
                    previewWidth={previewWidth}
                    rowData={rowData}
                    processForm={processForm}
                  />
                </Box>
              </Box>
            ) : null}
          </Allotment.Pane>
          <Allotment.Pane className="h-full flex flex-1">
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto',
                height: '100%',
                ml: 2,
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  overflow: 'auto',
                }}
              >
                <CommissionMapper
                  errors={errors}
                  setErrors={setErrors}
                  file={file}
                  fileType={fileType}
                  mpData={mpData}
                  rowData={rowData}
                  processForm={processForm}
                  setProcessForm={setProcessForm}
                  extraction={extractTableRaw}
                  selectedSheet={selectedSheet}
                  setSelectedSheet={setSelectedSheet}
                  spreadsheet={spreadsheet}
                  setProcessFormatData={setProcessFormatData}
                  processFormatData={processFormatData}
                  fileData={fileData}
                  addActionCount={addActionCount}
                />
              </Box>
              <Box>
                <StickyTips
                  tipsMap={errors}
                  data={processFormatData}
                  ref={tipRef}
                />
              </Box>
            </Box>
          </Allotment.Pane>
        </Allotment>
      </Box>
    </Box>
  );
};

export default forwardRef(ProcessUploads);
